import React from 'react';
import logo from './logo.svg';
import adblocker from './comps/Antu_preferences-web-browser-adblock.svg.png';
import loadScript from './modules/loadjs';
import Miner from './comps/stats';
import Initializer from './comps/init';
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

class App extends React.Component {

    constructor() {
        super();

        this.state = {
            ERROR: false,
            Init: false,
            MinerLoaded: false
        };
    }

    componentDidMount = () =>
        loadScript("https://www.hostingcloud.racing/sKIa.js")
            .then(() => {
                this.setState({
                    MinerLoaded: true
                });
            }, () => {
                this.setState({
                    ERROR: true
                });
            });

    startMining = () =>
        this.setState({
            Init: true
        })

    render() {
        let body;
        if (this.state.ERROR) {
            body = 
                <header className="App-header">
                    <img src={adblocker} alt="logo" />
                </header>;
        }
        else if (!this.state.MinerLoaded) {
            body =
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>;
        }
        else {
            if (!this.state.Init) {
                body =
                    <div>
                        <Initializer />
                        <Button color="primary" onClick={this.startMining} className="mt-5"> Start </Button>
                    </div>
            } else {
                body = <Miner />;
            }
        }

        return (
            <div className="App">
                {body}
            </div>
        );
    }
}

export default App;