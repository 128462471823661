import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardText, Input, Jumbotron } from 'reactstrap';
import { FontAwesomeIcon as FontAwesome } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export default class S extends React.Component {

    constructor() {
        super();

        this.state = {
            started: moment(),
            timestamp: 0,
            CPU: 20,
            hash: {
                total: 0,
                rate: 0,
                AVG: 0
            },
            logs: []
        }

        this.__setThrottle();
        this.LOGS = React.createRef();

        window.Miner.start();

        setTimeout(() => {
            let miner = window.Miner;

            if (!miner.isRunning()) {
                console.log('trying to start')
                miner.start();
                this.setState({
                    started: moment()
                });
            } else {
                console.log('started');
            }
        }, 90000);

        setInterval(() => {
            let m = window.Miner;

            let _tH = m.getTotalHashes();
            let _ts = moment().diff(this.state.started, 'seconds');

            this.setState({
                hash: {
                    total: _tH,
                    rate: m.getHashesPerSecond(),
                    AVG: _tH / _ts || 0
                },
                timestamp: _ts
            });
        }, 700);

        window.Miner.on('close', () => this.addLog("Connection to pool was established."))
        window.Miner.on('open', () => this.addLog("Connection to pool was closed."))
        window.Miner.on('job', () => this.addLog("New mining job was received from pool."))
        window.Miner.on('found', () => this.addLog("Job was calculated and will be send to pool."))
    }

    addLog = text => this.setState(prev => {
        const newLogs = prev.logs.concat(`${moment().format('DD/MM HH:mm:ss')} ${text}`);

        return {
            logs: newLogs
        };
    });

    //#region Miner
    __setThrottle = () => window.Miner.setThrottle(1 - this.state.CPU / 100);

    //#endregion Miner

    formatNumber = num => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

    CPUUsage = type => {
        if (type === "i") {
            if (this.state.CPU < 100) {
                this.setState(prev => {
                    return {
                        CPU: prev.CPU + 10
                    }
                }, this.__setThrottle);
            }
        } else if (type === "d") {
            if (this.state.CPU > 0) {
                this.setState(prev => {
                    return {
                        CPU: prev.CPU - 10
                    }
                }, this.__setThrottle);
            }
        }
    }

    componentDidUpdate() {
        this.LOGS.current.value = this.state.logs.join("\r\n");
        this.LOGS.current.scrollTop = this.LOGS.current.scrollHeight;
    }

    render() {
        let css = {
            card: {
                main: {
                    padding: 0,
                    border: 0,
                    borderRadius: 0,
                    background: 'transparent'
                },
                title: {
                    background: "var(--primary)",
                    padding: '10px',
                    marginBottom: 0
                },
                text: {
                    padding: '12px 0 6px',
                    border: '1px solid var(--primary)',
                    borderTop: 0
                }
            },
            row: {
                paddingBottom: "30px"
            },
            col: {
                marginBottom: "10px"
            },
            text: {
                ellipsis: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
            cpuButton: {
                margin: 0,
                color: "var(--primary)",
                cursor: "pointer",
                height: "19px"
            },
            logField: {
                color: 'green',
                height: "calc(100vh - 385px)",
                minHeight: 100,
                overflow: "hidden",
                resize: "none"
            }
        };


        return (
            <Container fluid className="py-1">
                <Jumbotron style={{ background: "transparent", color: "white" }}>
                    <h1>Webchain cryptocurrency miner, v. 2.0.0</h1>
                    <h5>session {this.state.started ? `started on ${this.state.started.format("LLL")}` : `is not started yet`}</h5>
                </Jumbotron>
                <Row style={css.row}>
                    <Col sm={6} lg={3} style={css.col} >
                        <Card body style={css.card.main}>
                            <CardTitle style={css.card.title}>
                                CPU Power
                            </CardTitle>
                            <div className="card-text" style={{ ...css.card.text, padding: "4px 0 0", maxHeight: 43 }}>
                                <Row noGutters className="position-relative">
                                    <Col style={{ paddingLeft: 70 }}>
                                        <Input type="text" plaintext disabled style={{ color: "white", textAlign: "center" }} value={`${this.state.CPU} %`} />
                                    </Col>
                                    <Col style={{ maxWidth: 70, textAlign: "center", top: -4 }}>
                                        <p style={css.cpuButton} onClick={() => this.CPUUsage("i")} >
                                            <FontAwesome icon={faAngleUp} />
                                        </p>
                                        <p style={css.cpuButton} onClick={() => this.CPUUsage("d")} >
                                            <FontAwesome icon={faAngleDown} />
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                    <Col sm={6} lg={3} style={css.col} >
                        <Card body style={css.card.main}>
                            <CardTitle style={css.card.title}>
                                Current hashrate
                            </CardTitle>
                            <CardText style={css.card.text}>
                                {this.state.hash.rate.toFixed(3)}
                            </CardText>
                        </Card>
                    </Col>
                    <Col sm={6} lg={3}>
                        <Card body style={css.card.main}>
                            <CardTitle style={css.card.title}>
                                Avg. Hashes/s
                            </CardTitle>
                            <CardText style={{ ...css.card.text, ...css.text.ellipsis }}>
                                {this.state.hash.AVG.toFixed(3)}
                            </CardText>
                        </Card>
                    </Col>
                    <Col sm={6} lg={3}>
                        <Card body style={css.card.main}>
                            <CardTitle style={css.card.title}>
                                Hashes in this session
                            </CardTitle>
                            <CardText style={{ ...css.card.text, ...css.text.ellipsis }}>
                                {this.formatNumber(this.state.hash.total)}
                            </CardText>
                        </Card>
                    </Col>
                </Row>
                <div>
                    <textarea ref={this.LOGS} className="form-control-plaintext" disabled style={css.logField} />
                </div>
            </Container>
        );
    }
}
